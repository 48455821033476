import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation-new";
import { Container } from "../components/global";
import Header from "../components/sections/terms-privacy/header";
import Footer from "../components/sections/footer/index-new";
import { Content, Section, P, H1, H2, UL, OL } from "../styles/TCandPrivacyStyles";

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Navigation background="white" />
    <Header {...pageData.headerData} />
    <Container>
      <Content>
        <P fontSize={["md", null, "lg"]}>
          {`At OPay Digital Services Limited (OPay), the privacy of our  (prospective) customers, users of our services and their Personal Data is of utmost importance to us. And in line with our resolution, we have developed this Privacy Policy to explain our privacy practices, particularly regarding how we collect, process, retain, share and protect your Personal Data when you visit our website, premises or use our digital platforms (collectively, "the Platform" or "Services")as well as your rights as a data subject..`}
        </P>
        <P fontSize={["md", null, "lg"]}>
          {`This Privacy Policy between OPay Digital Services Limited ("OPay") and you constitutes our commitment to protecting user privacy and compliance with the relevant data protection laws on all our platforms. It is designed to provide information regarding our privacy practices and help you understand how we handle your data, in full compliance with the provisions of the Nigeria Data Protection Act 2023 and other applicable data protection regulations. `}
        </P>
      </Content>

      <Content>
        <Section>
          <H1>1. Lawful basis for processing your data</H1>
          <P>
            Depending on the circumstances of our interactions with you, our legal basis for processing your personal
            data may be one of the following:
          </P>
          <P>
            <UL>
              <li>For the performance of a contract, we have or are about to enter with you;</li>
              <li>Based on your consent; or</li>
              <li>For our legitimate business interest or that of a third party.</li>
            </UL>
          </P>
        </Section>
        <Section>
          <H1>2. Consent</H1>
          <P>
            You give your consent to our processing of your personal data when you access our platforms or, when you
            accept our cookies policy; or in particular, as you use the services offered on our website and digital
            platforms or visit any of our offices and submit your data for official or non-official purposes.
          </P>
        </Section>

        <Section>
          <H1>3. What Personal Data do we collect?</H1>
          <P>
            We collect Personal Data about you when you use the services offered on our website and digital platform,
            including the following:
          </P>
          <OL>
            <li>
              <span>Sign-up information</span>: When you register to use the services offered on our digital platform,
              we will collect Personal Data as necessary to offer and provide the service you request. Depending on the
              service you choose, in line with regulatory requirements for Know Your Customer (KYC) purposes, we may
              require that you provide us with your name, age, mother’s maiden name, gender, birthday, facial photo,
              residential address, ID information (photo, number), utility bill, email address, mobile number, social
              media handles and debit/credit card details to create an account. We will also require your NIN and/or
              your BVN in line with regulation and in furtherance of our KYC computation processes. We may also require
              you to provide additional, necessary personal details as you use our services.
            </li>
            <li>
              <span>Transaction information</span>: When you use our digital platform to send and receive money, make
              purchases from merchants, pay bills, deposit & withdraw cash, we collect information about the
              transaction, as well as other associated information as necessary to offer and fulfil the service (like
              transaction receipt, account statement) that we are obliged to; such as: the amount sent or received,
              amount paid for products or services, merchant information – including information about your device and
              geolocation.
            </li>
            <li>
              <span>Participant Personal Data</span>: When you use our services, we may collect Personal Data about the
              other participants associated with the transaction, including your phone contacts with your consent, so
              you can easily transact with your friends and contacts and enable instant messaging (IM). We collect
              Personal Data such as name and financial account information about the participant who is receiving money
              from (or sending money to) you, when you send or receive money through the Services.
            </li>
            <li>
              <span>Image Information</span>: We may collect your image (upload, storage, and use) information to
              support account opening, such as uploading your portrait. We may also collect information from your images
              when you use our customer support to upload evidence such as statements and checks. In addition, we may
              collect your image information in connection with regulatory KYC purposes.When you visit any of our
              offices as well, our CCTV cameras may also process your images.
            </li>
            <li>
              <span>Information from other sources</span>: We may collect information from other sources, such as our
              social media platforms when you reach out to us to lodge a complaint about our services. However, we will
              only ask for information relevant to the help required of us to you. In addition, we also indirectly
              collect information from NIBSS, credit bureaus, NIMC, or other third-party KYC verification service
              providers.
            </li>
            <li>
              <span>Other information we collect related to your use of our website or Services</span>: We may collect
              additional information from or about you when you communicate with us, contact our customer support teams,
              respond to a survey or use functionality offered by third-party service providers through our Platform.
              For instance, when you initiate third-party applications through the OPay mobile application, with your
              prior consent, your SMS may be collected and monitored to help third-party service providers reduce risks
              associated with your application through the relevant model/system to provide customized services.
            </li>
            <li>
              <span>When you apply for a job with us</span>: We may request Personal Data about your education,
              employment and state of health. As part of your application, you will be asked to provide your express
              consent to our use of this information to assess your application and any monitoring activities which may
              be required of us under applicable laws as an employer. We may also carry out screening checks (including
              reference, background and criminal record checks). We may exchange your Personal Data with academic
              institutions, recruiters, health maintenance organisations, law enforcement agencies, referees and your
              previous employers. Without your Personal Data, we may not be able to process your application for
              positions with us.
            </li>
            <li>
              <span>We do not collect the information of minors</span>: If you are under the age of 18, you are not
              eligible to use the service offered on our digital platform.
            </li>
          </OL>
        </Section>

        <Section>
          <H1>4. How Do We Retain Personal Data?</H1>
          <P>
            We retain Personal Data in an identifiable format only for as long as required for our business purposes,
            with specific reference to the service rendered to you, and to fulfil our legal or regulatory obligations.
            Subject to applicable law, which might, from time to time, oblige us to retain your Personal Data for a
            certain period of time, we will retain your Personal Data for as long as necessary to fulfil the purposes we
            collect it for, including the purposes of satisfying any legal, accounting, or reporting obligations.{" "}
          </P>
        </Section>

        <Section>
          <H1>5. How Do We ProcessPersonal Data?</H1>
          <P>
            We collect your personal data to provide you an efficient and secure customer experience. We may process
            your Personal Data for the following reasons:
          </P>
          <OL>
            <li>
              Provide services, including to:
              <UL>
                <li>Initiate a payment, send or request money, or pay a bill;</li>
                <li>Authenticate your access to an account;</li>
                <li>Communicate with you about your account;</li>
              </UL>
            </li>
            <li>
              Manage risk, fraud and abuse of our services and prevent you from fraud (by developing and adopting
              measures of verifying your identity). Our risk and fraud tools use personal data, device information and
              geolocation from our Platform that offers services to help detect and prevent fraud and abuse of the
              services.
            </li>
            <li>
              Comply with our legal and regulatory obligations and to enforce the terms of our sites and services,
              including to comply with all applicable laws and regulations.
            </li>
            <li>Trail and track information(data) breach and remediate such identified breaches.</li>
            <li>Resolve disputes and troubleshoot problems.</li>
            <li>
              With your prior consent:
              <UL>
                <li>Market OPay products and services to you.</li>
                <li>Use cookies to provide a targeted display, feature, service or offer to you.</li>
                <li>
                  To provide you with location-specific functionality like searching nearby agents if you authorize us
                  to access your Geolocation information through the Services.
                </li>
              </UL>
            </li>
          </OL>
          <P>
            If/once you unsubscribe or object,We will stop sending further marketing communications to you by SMS or
            email if you have not opted in to receive them.
          </P>
          <P>Additionally, you can withdraw your consent at any time and free of charge.</P>
        </Section>

        <Section>
          <H1>6. Sharing your Personal Data</H1>
          <P>We may share your Personal Data or other information about you with others for the following reasons:</P>
          <UL>
            <li>
              With other entities of the OPay Group that are subject to the equivalent level of internal control: We may
              share your Personal Data with other entities under the OPay Group as necessary to enable the technical
              service you request, to manage risk through risk model system, to prevent potentially illegal and
              fraudulent acts and violations of our policies and agreements, and to help us manage the connectivity of
              our services.
            </li>
            <li>
              With other companies that provide services to us: We may share Personal Data with third-party service
              providers that perform services and functions at our direction and on our behalf. These third-party
              service providers may, for example, provide you with services, verify your identity, assist in processing
              transactions, send you advertisements for our products and services, or provide customer support. Rest
              assured that we make double sure that these companies have compliant, legal measures in place to ensure
              the security and confidentiality of your Personal Data.
            </li>
            <li>
              With unaffiliated thiry-party service providers that offer functionality through our Platform, with your
              prior consent, to help complete the service you request.
            </li>
            <li>
              With other financial institutions: We may share Personal Data with other financial institutions that we
              have partnered with to only offer OPay-related services, unless your consent extends to other uses. We may
              also share Personal Data to process transactions and keep your financial information up to date.
            </li>
            <li>
              With the other parties to transactions when you use the services, such as other users and their service
              providers. This includes other users you are sending or receiving funds from and their service providers.
              The information might include:{" "}
              <UL>
                <li>Personal Data and account information necessary to facilitate the transaction; and</li>
                <li>Personal Data to help other participant(s) resolve disputes and detect and prevent fraud.</li>
              </UL>
            </li>
            <li>
              {`Where permitted or required by law. We may share information about you with other parties for OPay's business purposes or as permitted or required by law, including:`}
              <OL>
                <li>
                  If we need to do so to comply with a court proceeding, other legal process or an applicable
                  regulation;
                </li>
                <li>
                  To law enforcement authorities or other government officials, or other third parties pursuant to a
                  court order or other legal process or requirement applicable to OPay or an affiliated entity;
                </li>
                <li>
                  Where we reasonably believe that the disclosure is necessary or appropriate to prevent an imminent
                  physical harm or financial loss, or in connection with an investigation of suspected (or actual)
                  illegal activity;
                </li>
                <li>
                  With credit agencies and data processors for credit reference checks and anti-fraud and compliance
                  purposes – all in accordance to applicable data privacy regulations and laws;
                </li>
                <li>
                  To investigate violations of or enforce a user agreement or other legal terms applicable to any
                  service;
                </li>
                <li>To protect our property, services and legal rights;</li>
                <li>{`As reasonably and legally permitted, to facilitate a purchase or sale of all or part of Opay's business, or in the instance of some merger and acquisition;`}</li>
                <li>To support our audit, compliance, and corporate governance functions.</li>
              </OL>
            </li>
            <li>
              With your consent: We also will share your Personal Data and other information with your consent or
              direction.
            </li>
          </UL>
        </Section>

        <Section>
          <H1>7. How We Use Cookies</H1>
          <P>
            Cookies are small files placed on your device’s browser that enable the website to identify your device as
            you view different pages. Like most interactive websites, our website uses cookies to enable us track of
            your activity for the duration of a session. Our website uses only encrypted session cookies which are
            erased either after a predefined timeout period or once the user logs out of the platform and closes the
            browser. Session cookies do not collect information from your device. They will typically store data in the
            form of a session identification that does not personally identify you. Certain aspects of our website are
            only available through the use of cookies, so your use of our website may be limited or not possible if you
            choose to disable or decline cookies. You may also choose to accept, decline, or manage cookie settings,
            which affects how you interact with our website and determines the functionality of the pages
          </P>
        </Section>

        <Section>
          <H1>8. What Are Your Rights?</H1>
          <OL>
            <li>
              <span>Requests to Access, Rectify or Erase.</span>
              <OL listStyle="lower-alpha">
                <li>
                  Access Request
                  <p>
                    As permitted under law and applicable data protection legislation, you have the right to ask us
                    whether we hold any Personal Data relating to you and, if we do, to be provided with a copy of that
                    Personal Data in electronic form, unless you want to receive it in any other way (for example, a
                    paper copy). In addition, you can ask us for information on how we use your Personal Data, who we
                    share it with, how long we keep it, where it is stored, and other information to help you understand
                    how we use it.
                  </p>
                </li>
                <li>
                  Rectification Request
                  <p>
                    You have the right to ask us to correct your Personal Data (including by means of providing a
                    supplementary statement) if it is inaccurate, or update outdated or incomplete Personal Data without
                    undue delay. Where we cannot correct the Personal Data, we include a note on our files regarding
                    your request to correct your Personal Data.
                  </p>
                </li>
                <li>
                  Erasure Request
                  <p>You have the right to ask us to erase your Personal Data if:</p>
                  <OL listStyle="lower-roman">
                    <li>Your Personal Data are no longer necessary for the purpose(s) they were collected for.</li>
                    <li>Your Personal Data have been unlawfully processed.</li>
                    <li>Your Personal Data must be erased to comply with a legislation or court order.</li>
                    <li>
                      You withdraw your consent for the processing of the Personal Data (and if this is the only basis
                      on which we are processing your Personal Data).
                    </li>
                    <li>
                      You object to the possession, provided there are no overriding legitimate grounds for continued
                      processing, or
                    </li>
                    <li>You object to processing for direct marketing purposes.</li>
                  </OL>
                </li>
              </OL>
              <P>
                If we receive your erasure request, we will also take reasonable steps to inform other data controllers
                processing the data so they can seek to erase links to or copies of your Personal Data. We may refuse to
                act on your request to erase your Personal Data if the processing of your Personal Data is necessary:{" "}
                <OL listStyle="lower-alpha">
                  <li>To exercise our right of freedom of expression and information.</li>
                  <li>To comply with the relevant Nigerian laws and regulations.</li>
                  <li>
                    For the performance of a task carried out in the public interest or to exercise some official
                    authority vested in us.
                  </li>
                  <li>To establish, exercise or defend legal claims.</li>
                  <li>To comply with pertinent legal and regulatory directives.</li>
                </OL>
              </P>
              <P>
                In these cases, we can restrict the processing instead of erasing your Personal Data if requested to do
                so by you.
              </P>
            </li>

            <li>
              <span>Object to processing:</span> 2.You have the right to object at any time to the processing of your
              Personal Data provided we do not have any other lawful basis to process it. We will stop processing your
              Personal Data unless we can demonstrate compelling legitimate reasons for continuing the processing. We
              may reject your request if the processing of your Personal Data is needed to establish, exercise or defend
              legal claims or other legitimate purposes. You have the right to object at any time if we process your
              Personal Data for direct marketing purposes. In such cases, we will stop processing your Personal Data
              when we receive your objection.
            </li>

            <li>
              <span>Requests to Restrict:</span> You have the right to ask us to restrict the processing of your
              Personal Data if:
              <UL>
                <li>
                  You contest the accuracy of your Personal Data and we are in the process of verifying the Personal
                  Data we hold.
                </li>
                <li>The processing is unlawful and you do not want us to erase your Personal Data.</li>
                <li>
                  We no longer need your Personal Data for the original purpose(s) of processing, but you need them to
                  establish, exercise or defend legal claims and you do not want us to delete the Personal Data as a
                  result, or
                </li>
                <li>
                  You have objected to processing carried out because of our legitimate interests while we verify if our
                  legitimate grounds override yours.
                </li>
              </UL>
              <P>
                If processing is restricted, we may process your Personal Data (excepting for storage purposes), only:
                <UL>
                  <li>If you have given us your consent.</li>
                  <li>For establishing, exercising or defending legal claims.</li>
                  <li>For protecting the rights of another natural or legal person, or</li>
                  <li>For reasons of important public interest as defined under the NDPA and relevant Nigerian laws</li>
                  <li>To comply with pertinent legal and regulatory directives.</li>
                </UL>
              </P>
              <P>
                Once processing is restricted following your request, we will inform you before we lift the restriction.
              </P>
            </li>
            <li>
              <span>Requests for Portability:</span> Subject to the directives of the Nigeria Data Protection Commission
              and technical possibilities, you can exercise you right to portability by requesting us to provide your
              data in a commonly used and machine-readable electronic format.
              {/* <UL>
                <li>
                  Receive any Personal Data you have provided to us in a structured, commonly used and machine-readable
                  electronic format.
                </li>
                <li>
                  Send your Personal Data to another organization or have us do so for you if it is technically feasible
                  for us to do so.
                </li>
              </UL> */}
              <P>
                If your request relates to a set of Personal Data that also concerns other individuals, we may restrict
                the transfer to only the portion relevant to you, unless you establish that you have also gotten their
                consent.
              </P>
            </li>
            <li>
              <span>Prohibition to Automated Decision-Making:</span> 3.Generally, you have the right to object to any
              decision concerning you or which otherwise significantly affects you if this is based solely on the
              automated processing of your Personal Data without human intervention. This includes automated decisions
              based on profiling.
              <P>
                However, we may refuse your request regarding such automated decisions where:
                <UL>
                  <li>Necessary to enter into a contract with you, or for the performance of your contract with us.</li>
                  <li>Permitted by regulations, or</li>
                  <li>Based on your explicit consent.</li>
                </UL>
              </P>
              <P>
                <span>Right to be informed: </span>
                The NDPA requires us, as controllers, to provide certain information to you before processing your
                personal data. It is in compliance with that obligation that we have published this privacy notice.
                Additional information are also provided in our Terms of use as well as other notices in our premises
                when you visit.
              </P>
              <P>
                <span>Withdrawal of consent: </span>
                Your decision to give consent to the processing of your data also comes with a corresponding right to
                withdraw the consent at any time. Hence, you have the right to communicate to us to stop processing your
                personal data with ot without reasons and we are duty-bound to stop such processing except other lawful
                bases exist for us to continue the processing activities.
              </P>
              <P>
                <span>Right to lodge complaint: </span>
                In the unlikely event of an infringement on any of your rights, you are at liberty to lodge complaint to
                the Nigeria Data Protection Commission (NDPC) or any other regulatory agency for redress.
              </P>
            </li>
          </OL>
        </Section>

        <Section>
          <H1>9. How Do We Protect Your Personal Data?</H1>

          <ol>
            <li>
              Security
              <P>
                We maintain technical, physical, and administrative security measures designed to provide reasonable
                protection for your Personal Data against loss, misuse, unauthorized access, disclosure, and alteration.
                The security measures include firewalls, data encryption, physical access controls to our premises, CCTV
                cameras for public safety and quality control, as well as information access authorization controls.
              </P>
            </li>

            <li>
              Confidentiality
              <p>
                Your Personal Data is regarded as confidential and will not be divulged to any third party, except under
                legal and/or regulatory conditions. You have the right to request sight of, and copies of any and all
                information we keep on you, if such requests are made in compliance with the Freedom of Information Act
                and other relevant enactments.{" "}
              </p>
            </li>
          </ol>

          <p>
            While we are dedicated to securing our systems and services and safeguarding the information entrusted to
            us, your role in fulfilling confidentiality duties includes, but is not limited to, adopting and enforcing
            appropriate security measures such as securing and maintaining the privacy of your password(s) and
            account/profile registration information, adherence with physical security protocols on our premises,
            verifying that the Personal Data we maintain of you is accurate and current.{" "}
          </p>
          <P>We will inform you of any breaches which may likely result in high risks to your rights and freedom.</P>
          <ol>
            <li>
              Organisational measures
              <P>
                We have drafted policies and other documents that ensure the safety, integrity and confidentiality of
                your personal data. We also periodically train and equip our authorised staff on the contemporary
                measures for data security.
              </P>
            </li>
          </ol>
        </Section>

        <Section>
          <H1>10. Remedies for Violation and Time-frame for Remedy</H1>

          <P>
            In the unlikely event of violation of any of your rights a data subject, our Data Protection Officer shall
            within 30 days of our notice of the said violation, address the issue and redress the violation as
            practicable as possible. The available remedies include but not limited to correction or deletion of your
            data, grant of access to your data, due information on the processing of your data, restriction on further
            processing and other applicable remedies as the relevant laws prescribe.
          </P>
        </Section>

        {/* <Section>
          <H1>11. Governing Law</H1>

          <P>
            This Privacy Policy is made according to the Nigeria Data Protection Act (2023) or any other relevant
            Nigerian laws, regulations or international conventions applicable to Nigeria.
          </P>
        </Section> */}

        <Section>
          <H1>11. Changes to our Privacy Policy</H1>

          <P>
            This privacy policy is reviewed periodically and when there is any substantial change to business or
            regulatory requirements. The revised Privacy Policy will be effective as of the published updated date. At
            the minimum, we shall review this annually and communicate via our communication channels such as Website,
            Social Media Accounts etc. If the revised version includes a substantial change, we may notify you of the
            change using emails or other means.{" "}
          </P>
        </Section>

        {/* <Section>
          <H1>12. How Does Your Personal Data Move Globally?</H1>

          <P>
            Our operations are supported by a network of computers, cloud-based services and other infrastructure and
            information technology. Your Personal Data will be stored in Nigeria where we are providing Services to you,
            except where you provide your consent for your Personal Data to be processed outside Nigeria to enable us
            process your transactions. Data are stored with appropriate encryption menthod and security standards. We
            will protect your information as prescribed in the Privacy Policy if your Personal Data is transferred out
            of Nigeria. By using our Platform and Services, you consent to your Personal Data being transferred out of
            Nigeria, to a jurisdiction which has adequate data protection laws as prescribed in the NDPA.{" "}
          </P>
        </Section> */}

        <Section>
          <H1>12. Contact Us</H1>

          <P>
            If you have any general questions or concerns about this Privacy Policy or the way in which we handle your
            Personal Data, kindly contact us via the details below:
          </P>
          <P fontWeight="semibold" mb="0 !important">
            OPAY DIGITAL SERVICES LIMITED
          </P>
          <P mb="0 !important">Alexander House, Plot 9, Nurudeen Olowopopo Way, Alausa-Ikeja, Lagos, Nigeria.</P>

          <P>
            Email: <a href="mailto:ng-privacy@opay-inc.com">ng-privacy@opay-inc.com</a>
          </P>
        </Section>
      </Content>
    </Container>
    <Footer />
  </Layout>
);

export default PrivacyPolicy;

const pageData = {
  headerData: {
    captionText: "Privacy Policy",
    subTitleText: "Version 7.0 | May 27, 2024",
  },
};
